
#footer{
    width: 100% !important;
    min-height: 10px !important;
    max-width: 100% !important;
    background-color: #495057;
}

.top{
    background-color: #495057;
    padding: 30px 30px 0px;
    max-width: 100% !important;
}

.bottom{
    background-color: #212529;
    color: #fff;
    padding: 15px 30px;
}

.col-md-6 h5{
    color: #fff
}
#footer .col-md-6 label{
    color: #fff
}

.col-md-6 .form-group{
    margin-bottom: 10px !important;
}

.col-md-6 .button{
    margin: 10px 0 !important;
}

#footer h6{
    color: #fff;
    text-align: left;
}

#footer span{
    color: #fff;
    font-size: 14px;
    font-weight: normal;
}

p span {
    margin-left: 15px;
}